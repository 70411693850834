span.price{
    font-size: 30px;
    font-weight: bolder;
    vertical-align: middle;
    padding-right: 10px;
}

.plot-list-price{
    font-size: 20px;
    font-weight: bolder;
}

.plot-list-type{
    font-size: 10px;
    color: #000;
}

.plot-list-header{
    font-size: 15px;
    color: #000;
    font-weight: bolder;
}

.plot-list-details-header{
    font-size: 15px;
    color: #000;
    font-weight: bolder;
    padding-bottom: 10px;
}

.single-plot-price{
    color:blueviolet;
    font-weight: bolder;
}

.single-plot-photo-huge-header{
    font-weight: bolder;
    padding-left: 5px;
}

.single-plot-photo-huge-content{
    padding-left: 5px
}


